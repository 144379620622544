import React from "react";

function Paragraph(props) {
  return (
    <>
      <p>{props.ptext}</p>
    </>
  );
}
export default Paragraph;
