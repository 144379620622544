import React, { useEffect } from "react";
import "./MeetOurTeam.css";
import Paragraph from "../Paragraph";
import Pagetitlesection from "../Pagetitlesection";
import Accordion from "../Accordion";
import "./MeetOurTeam.css";
import Accordion2 from "../Accordion2";

function MeetOurTeam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Meet_Our_Team section-header-margin-top ">
        <Pagetitlesection pagetitle="Meet Our Team" />
        <div className="container">
          <section>
            <h3 className="green-color page-title">
              Our Leardership Speaks Our Quality
            </h3>
            <Paragraph ptext="The mission of Sage Clinic guides the team in delivering cutting-edge, superior family-centered healthcare to all Deltans as well as citizens of surrounding states and localities. Team members are dedicated to assisting all patients in achieving optimal health, regardless of their ethnicity, color, tribe, religion, country of origin, age, or financial status. " />
          </section>

          <section className="accordion-wrapper">
            <Accordion h4text="Medical Team" />
            <div className="faint-border-line"></div>
            <Accordion2 h4text="Administrative Team" />
            <div className="faint-border-line"></div>
            <Accordion h4text="Management Team" />
          </section>
        </div>
      </div>
    </>
  );
}

export default MeetOurTeam;
