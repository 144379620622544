import React, { useEffect } from "react";
import Pagetitlesection from "../Pagetitlesection";
import Paragraph from "../Paragraph";
import Unorderderlist3 from "../Unorderlist3";
import Unorderderlist4 from "../Unorderlist4";
import Unorderderlist5 from "../Unorderlist5";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Privacy_Policy section-header-margin-top ">
        <Pagetitlesection pagetitle="Privacy Policy" />
      </div>
      <div className="container">
        <section>
          <div className="m-b3">
            <h4 className="light-green-color">Last updated: July 02, 2024</h4>
            <Paragraph ptext="This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You." />
            <Paragraph ptext="We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. " />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">
              Interpretation and Definitions
            </h5>

            <h5 className="light-green-color">Interpretation</h5>
            <Paragraph ptext="The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural." />

            <h5 className="light-green-color">Definitions</h5>
            <Paragraph ptext="For the purposes of this Privacy Policy:" />
            <p>
              <strong className="green-color paragraph-text-small-size">
                Affiliate{" "}
              </strong>{" "}
              means an entity that controls, is controlled by or is under common
              control with a party, where "control" means ownership of 50% or
              more of the shares, equity interest or other securities entitled
              to vote for election of directors or other managing authority.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Company{" "}
              </strong>{" "}
              (referred to as either "the Company", "We", "Us" or "Our" in this
              Agreement) refers to Sage Clinic, 29 Okere Ugborikoko Road, Warri,
              332104, Delta State, Nigeria.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Device{" "}
              </strong>{" "}
              means any device that can access the Service such as a computer, a
              cellphone or a digital tablet
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Personal Data{" "}
              </strong>{" "}
              is any information that relates to an identified or identifiable
              individual.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Service{" "}
              </strong>{" "}
              refers to the Website.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Service Provider{" "}
              </strong>{" "}
              means any natural or legal person who processes the data on behalf
              of the Company. It refers to third-party companies or individuals
              employed by the Company to facilitate the Service, to provide the
              Service on behalf of the Company, to perform services related to
              the Service or to assist the Company in analyzing how the Service
              is used.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Affiliate{" "}
              </strong>{" "}
              means an entity that controls, is controlled by or is under common
              control with a party, where "control" means ownership of 50% or
              more of the shares, equity interest or other securities entitled
              to vote for election of directors or other managing authority.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Usage Data{" "}
              </strong>{" "}
              refers to data collected automatically, either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Website{" "}
              </strong>{" "}
              refers to Sage Clinic, accessible from
              http://www.thesageclinic.com
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                You{" "}
              </strong>{" "}
              means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </p>
          </div>

          <div className="m-b3">
            <h5 className="light-green-color">
              Collecting and Using Your Personal Data
            </h5>
            <h6 className="light-green-color">Types of Data Collected</h6>
            <b className="green-color paragraph-text-small-size">
              Personal Data.
            </b>
            <Paragraph ptext="While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:" />
            <Unorderderlist4
              list1="Email address"
              list2="First name and last name."
              list3="Phone number"
              list4="Address, State, Province, ZIP/Postal code, City"
            />
            <b className="light-green-color paragraph-text-small-size">
              Usage Data.
            </b>
            <Paragraph ptext="Usage Data is collected automatically when using the Service." />
            <Paragraph ptext="Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data." />
            <Paragraph ptext="When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data." />
            <Paragraph ptext="We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device." />
          </div>
          <div className="m-b3">
            <h6 className="light-green-color">Use of Your Personal Data</h6>
            <p>
              <strong className="green-color paragraph-text-small-size">
                To provide and maintain our Service,{" "}
              </strong>{" "}
              including to monitor the usage of our Service.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                For the performance of a contract:{" "}
              </strong>{" "}
              the development, compliance and undertaking of the purchase
              contract for the products, items or services You have purchased or
              of any other contract with Us through the Service.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                To contact You:{" "}
              </strong>{" "}
              To contact You by email, telephone calls, SMS, or other equivalent
              forms of electronic communication, such as a mobile application's
              push notifications regarding updates or informative communications
              related to the functionalities, products or contracted services,
              including the security updates, when necessary or reasonable for
              their implementation.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                To provide You with news,{" "}
              </strong>{" "}
              special offers and general information about other goods, services
              and events which we offer that are similar to those that you have
              already purchased or enquired about unless You have opted not to
              receive such information.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                To manage Your requests:{" "}
              </strong>{" "}
              To attend and manage Your requests to Us.
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                For other purposes:{" "}
              </strong>{" "}
              We may use Your information for other purposes, such as data
              analysis, identifying usage trends, determining the effectiveness
              of our promotional campaigns and to evaluate and improve our
              Service, products, services, marketing and your experience.{" "}
            </p>

            <Paragraph ptext="Usage Data is collected automatically when using the Service." />

            <p>
              <strong className="green-color paragraph-text-small-size">
                With Service Providers:{" "}
              </strong>{" "}
              We may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You..{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                With Affiliates:{" "}
              </strong>{" "}
              We may share Your information with Our affiliates, in which case
              we will require those affiliates to honor this Privacy Policy.
              Affiliates include Our parent company and any other subsidiaries,
              joint venture partners or other companies that We control or that
              are under common control with Us.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                With business partners:{" "}
              </strong>{" "}
              We may share Your information with Our business partners to offer
              You certain products, services or promotions.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                With other users:{" "}
              </strong>{" "}
              when You share personal information or otherwise interact in the
              public areas with other users, such information may be viewed by
              all users and may be publicly distributed outside.{" "}
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                With Your consent:{" "}
              </strong>{" "}
              We may disclose Your personal information for any other purpose
              with Your consent.{" "}
            </p>
          </div>

          <div className="m-b3">
            <h6 className="light-green-color">
              Disclosure of Your Personal Data
            </h6>
            <b className="light-green-color paragraph-text-small-size">
              Law enforcement
            </b>

            <Paragraph ptext="Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)." />

            <b className="green-color paragraph-text-small-size">
              Other legal requirements
            </b>
            <Paragraph ptext="The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: " />
            <Unorderderlist5
              list1="Comply with a legal obligation."
              list2="Protect and defend the rights or property of the Company."
              list3="Prevent or investigate possible wrongdoing in connection with the Service."
              list4="Protect the personal safety of Users of the Service or the public."
              list5="Protect against legal liability."
            />
          </div>
          <div className="m-b3">
            <b className="light-green-color paragraph-text-small-size">
              Security of Your Personal Data{" "}
            </b>

            <Paragraph ptext="The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security." />
          </div>
          <div className="m-b3">
            <b className="light-green-color paragraph-text-small-size">
              Children's Privacy{" "}
            </b>

            <Paragraph ptext="Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers." />
            <Paragraph ptext="If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information." />
          </div>
          <div className="m-b3">
            <b className="light-green-color paragraph-text-small-size">
              Links to Other Websites{" "}
            </b>

            <Paragraph ptext="Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit." />
            <Paragraph ptext="We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services." />
          </div>
          <div className="m-b3">
            <b className="light-green-color paragraph-text-small-size">
              Changes to this Privacy Policy{" "}
            </b>

            <Paragraph ptext="We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page." />
            <Paragraph ptext="We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy." />
            <Paragraph ptext="You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page." />
          </div>
          <div className="m-b3">
            <b className="light-green-color paragraph-text-small-size">
              Contact Us{" "}
            </b>

            <Paragraph ptext="If you have any questions about this Privacy Policy, You can contact us:" />
            <Unorderderlist3
              list1="By email: support@sageclinicandmaternity.com"
              list2="By visiting this page on our website: http://www.sageclinicandmaternity.com."
              list3="By phone number: +234 (0) 907 389 5937"
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default PrivacyPolicy;
