import React, { useEffect } from "react";
import Paragraph from "../Paragraph";
import Pagetitlesection from "../Pagetitlesection";
import Unorderderlist5 from "../Unorderlist5";
import { Link } from "react-router-dom";

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Privacy_Policy section-header-margin-top ">
        <Pagetitlesection pagetitle="Terms of Use" />
      </div>
      <div className="container">
        <section>
          <div className="m-b3">
            <h4 className="light-green-color">Last updated: July 02, 2024</h4>
            <Paragraph ptext="The Sage Clinic website located at http://www.sageclinicandmaternity.com is a copyrighted work belonging to Sage Clinic . Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features." />
            <Paragraph ptext="All such additional terms, guidelines, and rules are incorporated by reference into these Terms. " />
            <Paragraph ptext="These Terms of Use described the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE. " />
            <Paragraph ptext="These terms require the use of arbitration Section 10.2 on an individual basis to resolve disputes and also limit the remedies available to you in the event of a dispute.  " />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">Access to the Site</h5>

            <b className="green-color paragraph-text-small-size">
              Subject to these Terms
            </b>

            <Paragraph ptext="Sage Clinic grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use." />

            <b className="green-color paragraph-text-small-size">
              Certain Restrictions
            </b>
            <Paragraph ptext="The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site must be retained on all copies thereof." />
            <Paragraph ptext="Sage Clinic reserves the right to change, suspend, or cease the Site with or without notice to you.  You approved that Sage Clinic will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part." />

            <b className="green-color paragraph-text-small-size">
              No Support or Maintenance
            </b>
            <Paragraph ptext="You agree that Sage Clinic will have no obligation to provide you with any support in connection with the Site." />
            <Paragraph ptext="Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Sage Clinic. Note that these Terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights, except for the limited access rights expressed in Section 2.1. Sage Clinic reserve all rights not granted in these Terms." />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">Disclaimers</h5>
            <Paragraph ptext="The site is provided on an 'as-is' and 'as available' basis, and Sage Clinic and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.  If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use. " />
            <Paragraph ptext="Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.  Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you. " />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">Limitation on Liability</h5>
            <Paragraph ptext="To the maximum extent permitted by law, in no event shall Sage Clinic or our suppliers be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if Sage Clinic has been advised of the possibility of such damages.  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom." />
            <Paragraph ptext="Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you. " />
            <b className="green-color paragraph-text-small-size">
              Term and Termination.
            </b>
            <Paragraph ptext="Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases.  Sage Clinic will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2 through 2.5, Section 3 and Sections 4 through 10. " />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">Copyright Policy.</h5>
            <Paragraph ptext="Sage Clinic respects the intellectual property of others and asks that users of our Site do the same.  In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights.  If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification must be provided to our designated Copyright Agent: " />
            <Unorderderlist5
              list1="Your physical or electronic signature;"
              list2="Identification of the copyrighted work(s) that you claim to have been infringed."
              list3="Identification of the material on our services that you claim is infringing and that you request us to remove."
              list4="Sufficient information to permit us to locate such material;."
              list5="A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law."
            />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">General</h5>
            <Paragraph ptext="These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes." />
            <b className="green-color paragraph-text-small-size">
              Your Privacy.
            </b>
            <p>
              Please read our
              <Link
                to="/PrivacyPolicy"
                className="ul-link remove-decor light-green-color"
                id="move-left"
              >
                Privacy Policy.
              </Link>
            </p>
            <b className="green-color paragraph-text-small-size">
              Copyright/Trademark Information.
            </b>
            <Paragraph ptext="Copyright ©. All rights reserved.  All trademarks, logos and service marks displayed on the Site are our property or the property of other third-parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks." />
          </div>
          <div className="m-b3">
            <h5 className="light-green-color">Contact Information</h5>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Address:{" "}
              </strong>{" "}
              29 Okere Ugborikoko Road, Warri, 332104, Delta State, Nigeria
            </p>
            <p>
              <strong className="green-color paragraph-text-small-size">
                Email:{" "}
              </strong>{" "}
              contact@sageclinicandmaternity.com{" "}
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default TermsOfUse;
