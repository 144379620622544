import React, { useEffect } from "react";
import Pagetitlesection from "../Pagetitlesection";
import "./Testimonies.css";
import Paragraph from "../Paragraph";
import TestifierComment from "../TestifierComment";
import TestifyPhoto from "../TestifyPhoto";

function Testimonies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Testimonials section-header-margin-top ">
        <Pagetitlesection pagetitle="Testimonies" />
        <section>
          <div className="container">
            <div>
              <h1 className="green-color testifier-header-text page-title">
                Our Patient Stories
              </h1>
              <Paragraph ptext="Hear inspiring tales of actual Sage Clinic Patient" />
            </div>
            <div className="testifiers">
              <div className="row flex colum-reverse">
                <div className="col-70">
                  <TestifierComment
                    ptext="
I cannot say enough positive things about Sage Clinic. From the moment I walked through their doors, I was greeted with a warm and welcoming atmosphere. The nurses and doctors at Sage Clinic are not only knowledgeable, but also incredibly caring. They take the time to listen to their patients and provide personalized care. They are always there when I need them, whether it's for a last-minute appointment or a phone call with a question. 
This level of dedication and accessibility is truly commendable and has made a huge difference in my overall experience as a patient.

I highly recommend Sage Clinic to anyone in need of medical care. Thank you, Sage Clinic, for all that you do! . "
                  />
                </div>
                <div className="col-30">
                  <TestifyPhoto
                    image="/assets/images/family.jpg"
                    htext="Kristen John"
                  />
                </div>
              </div>
              <div className="faint-border-line from-left"></div>
              <div className="row flex">
                <div className="col-30">
                  <TestifyPhoto
                    image="/assets/images/pexels-christina-morillo-1181519(1).jpg"
                    htext="Edafe Catherine"
                  />
                </div>
                <div className="col-70 odd-comment">
                  <TestifierComment
                    ptext="Each time I visit Sage Clinic for treatment, I was always greeted with warm smiles and a receptive approach from the staff. The atmosphere of the clinic was calming and inviting, making it the perfect environment for healing and wellness. The staff at Sage Clinic truly go above and beyond to ensure their patients feel cared for and supported. Thank you, Sage Clinic, for your exceptional service and for making me feel like a valued patient.  
                   "
                  />
                </div>
              </div>
              <div className="faint-border-line from-right"></div>
              <div className="row flex colum-reverse">
                <div className="col-70">
                  <TestifierComment ptext="I recently had the pleasure of visiting Sage Clinic and I must say, I was thoroughly impressed. The first thing that caught my attention was the neat and serene environment. It immediately put me at ease and made me feel comfortable. But what truly stood out to me were the doctors at Sage Clinic. Not only were they highly professional and knowledgeable, but they were also incredibly intelligent. They took the time to listen to my concerns and provided me with the best possible treatment plan. I left Sage Clinic feeling confident and reassured, knowing that I had received top-notch care from the best doctors." />
                </div>
                <div className="col-30">
                  <TestifyPhoto
                    image="/assets/images/images3.jpg"
                    htext="Okoro Jane"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Testimonies;
