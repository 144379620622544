import React from "react";
import "./Accreditation.css";

function Accreditation(props) {
  return (
    <>
      <div className="acc-wrapper">
        <div className="flex-row">
          <div className="col-30">
            <img
              src={props.image}
              alt={props.alt}
              className="Accreditation-logo"
            />
          </div>
          <div className="acc-content col-70">
            <h5 className="text-left light-green-color">{props.acc_title}</h5>
            <p className="text-left">{props.p_text}</p>
            <div className="faint-border-line"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accreditation;
