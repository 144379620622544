import React, { useEffect, useState } from "react";
import Pagetitlesection from "../Pagetitlesection";
import { Icon } from "@iconify/react";
import "./Contact.css";
import Paragraph from "../Paragraph";
import axios from "axios";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set initial state for the form data
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    message: "",
  });

  // Handle form data changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Make HTTP POST request to PHP script
    axios
      .post("/submit.php", formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="Contact_us section-header-margin-top">
        <Pagetitlesection pagetitle="Contact Us" />
        <section>
          <div className="container">
            <h4 className="green-color">Phone Directory</h4>
            <table className="table-width">
              <tr>
                <th className="col-70">Frontdesk/Reception</th>
                <td className="col-30">+234 (0) 805 272 3537</td>
              </tr>
              <tr>
                <th className="secondary-color col-70">Nurses Station</th>
                <td className="col-30">+234 (0) 814 436 2120</td>
              </tr>
              <tr>
                <th className="secondary-color col-70">Pharmacy</th>
                <td className="col-30">+234 (0) 803 065 0611</td>
              </tr>
              <tr>
                <th className="secondary-color col-70">Laboratory</th>
                <td className="col-30">+234 (0) 813 422 6004</td>
              </tr>
              <tr>
                <th className="secondary-color col-70">Radiology</th>
                <td className="col-30">+234 (0) 813 503 9522</td>
              </tr>
              <tr>
                <th className="secondary-color col-70">Doctors</th>
                <td className="col-30">+234 (0) 803 912 4042</td>
              </tr>
            </table>

            <div className="contact-information table-width">
              <Paragraph ptext="Please fill out the form below if you have any additional questions concerning Sage Clinic & Maternity." />
              <div className="form-width">
                <h4 className="green-color">Contact Information</h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-row align-start">
                    <div className="col-50">
                      <div className="col-95">
                        <input
                          type="text"
                          name="fname"
                          id="name"
                          placeholder="First Name"
                          value={formData.fname}
                          onChange={handleInputChange}
                        />
                        <input
                          placeholder="Last Name"
                          type="text"
                          name="lname"
                          id="lname"
                          value={formData.lname}
                          onChange={handleInputChange}
                        />
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          placeholder="Phone Number"
                          value={formData.mobile}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-50">
                      <div className="col-95 align-right">
                        <textarea
                          placeholder="Your Message"
                          cols={5}
                          name="message"
                          id="message"
                          value={formData.message}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <button type="submit" className="bg-green white-color">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="address-email">
              <div>
                <h5 className="green-color">
                  <Icon icon="ion:home" id="email" /> <span>Our Location</span>
                </h5>
                <p>29 Okere Ugborikoko Road, Warri,</p>
                <p>332104, Delta State, Nigeria</p>
                <p>
                  <a
                    href="https://www.google.com/maps/dir//Sage+Clinic,+29+Okere+Ugborikoko+Road,+Warri,+332104,+Delta/@5.5346587,5.7525573,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1041ad0c761eb58b:0x5ae911c432fef009!2m2!1d5.7551322!2d5.5346587?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="light-green-color remove-decor,"
                  >
                    <b className="Direction">Get Driving Direction</b>
                  </a>
                </p>
              </div>
              <div className="m-tp3">
                <h5 className="green-color">
                  <Icon icon="material-symbols-light:attach-email" id="email" />
                  <span>Email</span>
                </h5>

                <p>
                  <a href="mailto:info@sageclinicandmaternity.com">
                    <b className="light-green-color">
                      info@sageclinicandmaternity.com
                    </b>
                  </a>
                </p>
                <p>
                  <a href="mailto:support@sageclinicandmaternity.com">
                    <b className="light-green-color">
                      support@sageclinicandmaternity.com
                    </b>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;
